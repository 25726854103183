import React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import Hero from '../components/Hero';
import Image from '../components/Image';
import OutboundLink from '../components/OutboundLink';
import FooterCallout from './partials/_FooterCallout';
import Girls4TechCallout from './partials/_Girls4TechCallout';
import HeroImage from '../images/hero/hero-about.png';
import { useTranslation } from 'react-i18next';

const AboutPage = ({ data }) => {
  const { t } = useTranslation(['about', 'common']);

  return (
    <Layout title="About the Program">
      <Hero title={t('common:aboutTheProgram')} image={HeroImage} />
      <Section className="about__intro">
        <Container>
          <Row>
            <Column size={6}>
              <h2>{t('inspiringStemHeader')}</h2>
              <p dangerouslySetInnerHTML={{ __html: t('inspiringStemText') }} />
              <OutboundLink
                to="https://girls4tech.com"
                className="text-bold"
                withIcon
              >
                {t('common:girls4TechCalloutLink')}
              </OutboundLink>
            </Column>
            <Column size={6}>
              <Image filename="img-orange-laptop.png" />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="about__partners">
        <Container>
          <h2 className="about__partners-heading">
            {t('aboutThePartnersHeader')}
          </h2>
          <Row>
            <Column size={6} className="about-mission__content">
              <Image
                filename="logo-mastercard.svg"
                className="about__partners-logos mastercard"
              />
              <p>
                <strong>{t('aboutThePartnersTextBold')}</strong>{' '}
                {t('aboutThePartnersText')}
              </p>
            </Column>
            <Column size={6} className="about-mission__content">
              <Image
                filename="de-logo-positive.png"
                className="about__partners-logos de"
              />
              <p>
                <strong>{t('discoveryEducationTextBold')}</strong>{' '}
                {t('discoveryEducationText')}
                <OutboundLink to="https://www.discoveryeducation.com">
                  www.discoveryeducation.com
                </OutboundLink>
                .
              </p>
            </Column>
          </Row>
        </Container>
      </Section>
      <FooterCallout
        text={<p dangerouslySetInnerHTML={{ __html: t('subfooterText') }} />}
        className="about__crucial-skills"
      />
      <Girls4TechCallout />
    </Layout>
  );
};

export default AboutPage;
