import React from 'react';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import Image from '../../components/Image';
import OutboundLink from '../../components/OutboundLink';
import { useTranslation } from 'react-i18next';

const Girls4TechCallout = () => {
  const { t } = useTranslation(['common']);
  return (
    <Section>
      <Container>
        <Row verticalAlignCenter>
          <Column size={3} offset={1}>
            <Image filename="program-logo.svg" />
          </Column>
          <Column size={7}>
            <p>{t('girls4TechCalloutText')}</p>
            <OutboundLink
              to="https://www.girls4tech.com"
              withIcon
              className="text-bold"
            >
              {t('girls4TechCalloutLink')}
            </OutboundLink>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

export default Girls4TechCallout;
